import { defineStore } from "pinia";

interface Apontamentos {
  name: Number;
  nome_operador: String;
  recurso: String;
  desc_recurso: String;
  dt_inicio: String;
  dt_fim: String;
  ordem_de_producao: String;
  status: String;
  observacao: String;
  tipo : String;
  serial : String;
  motivo_de_parada : String;
  desc_motivo_parada : String;
  origem_apontamento : String;
  turno : String;
}

interface Maquinas {
  id: String;
  imagem: String;
  leitor: String;
  centro_de_custo: String;
  nome: String;
  operador_padrao: String;
  tipo_apontamento: String;
  apontamentos: Apontamentos[];
}

import { useAppStore } from "./app";

export const useAppMaquinas = defineStore({
  id: "appMaquinas",
  state: (): {
    resourceMaquinas: Maquinas[];
  } => ({
    resourceMaquinas: [],
  }),
  getters: {},
  actions: {
    async listar_maquinas() {
      try {
        this.resourceMaquinas = [];

        const res = await frappe.call(
          "nxlite.nx_producao.page.apontamento_iot.controller.get_all_recurso",
          { limit: 15 }
        );

        res.message.forEach((maquina: any) => {
          const apontamentos: Apontamentos[] = [];

          if (Array.isArray(maquina.apontamentos)) {
            maquina.apontamentos.forEach((apontamento: Apontamentos) => {
            
              apontamentos.push({
                name: apontamento.name,
                nome_operador: apontamento.nome_operador,
                recurso: apontamento.recurso,
                desc_recurso: apontamento.desc_recurso,
                dt_inicio: apontamento.dt_inicio,
                dt_fim: apontamento.dt_fim,
                ordem_de_producao: apontamento.ordem_de_producao,
                status: apontamento.status,
                observacao : apontamento.observacao,
                tipo : apontamento.tipo,
                serial : apontamento.serial,
                motivo_de_parada : apontamento.motivo_de_parada,
                desc_motivo_parada : apontamento.desc_motivo_parada,
                origem_apontamento : apontamento.origem_apontamento,
                turno : apontamento.turno

              });
            });
          } else {
            console.error("maquina.apontamentos não é um array");
          }

          this.resourceMaquinas.push({
            id: maquina.name,
            imagem: maquina.imagem,
            leitor: maquina.leitor,
            centro_de_custo: maquina.centro_de_custo,
            nome: maquina.nome,
            operador_padrao: maquina.operador_padrao,
            tipo_apontamento: maquina.tipo_apontamento,
            apontamentos: apontamentos,
          });
        });
       
      } catch (error) {
        throw new Error(error);
      }
    },
    update_recurso_realtime(recurso_doc : any) {
      const new_data = {
        id: recurso_doc.name,
        imagem: recurso_doc.imagem,
        leitor: recurso_doc.leitor,
        centro_de_custo: recurso_doc.centro_de_custo,
        nome: recurso_doc.nome,
        operador_padrao: recurso_doc.operador_padrao,
        tipo_apontamento: recurso_doc.tipo_apontamento,
        apontamentos: recurso_doc.apontamentos,
      };

      const found = this.resourceMaquinas.find((data) => data.id == recurso_doc.name);

      if (found) {
        const new_resource = this.resourceMaquinas.map((data) =>
          data.id == recurso_doc.name ? new_data : data
        );
        
        this.resourceMaquinas = new_resource;
      }
      else {
        this.resourceMaquinas.push(new_data)
      }
    },
    update_apontamento_realtime(d) {
      if (Object.keys(d).includes("recurso")) {
        const selected_recurso_id = useAppStore().maquina_sel.id;
        console.log("d:", d);
        const { apontamento, recurso } = d;
        const prev_resource = this.resourceMaquinas;
        const new_resource = prev_resource.map((data) => {
          console.log("prev_resource:", data);
          if (data.id != recurso.name) return data;
          if (!data.apontamentos)
            return { ...data, apontamentos: [apontamento] };
          if (data.apontamentos.length == 0)
            return { ...data, apontamentos: [apontamento] };
          const found = data.apontamentos.find(
            (apt) => apt.name == apontamento.name
          );
          if (!found) {
            let new_apontamentos = data.apontamentos;
            new_apontamentos.unshift(apontamento)
            console.log(new_apontamentos);
            if (selected_recurso_id == recurso.name) {
              useAppStore().apontamentos_historico = new_apontamentos;
            }
            return {
              ...data,
              apontamentos: new_apontamentos,
            };
          }

          const new_apontamentos = data.apontamentos.map(e =>
            e.name == apontamento.name ? apontamento : e
          );
          if (selected_recurso_id == recurso.name) {
            useAppStore().apontamentos_historico = new_apontamentos;
          }
          const new_data = { ...data, apontamentos: new_apontamentos };
         
          return new_data;
        });
        this.resourceMaquinas = new_resource;
        console.log("new_resource_maquinas", this.resourceMaquinas);
      }
    },
  },
  persist: {
    enabled: true,
  },
});
