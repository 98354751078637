<template>
    <div class="buttons">
        <v-row v-if="appStore.apontamento_atual.tipo == 'Parada'" class="pt-0" justify="center">
            <small class="pt-0" style="font-weight: bold;font-size: 18px;color:#0D47A1">MOTIVO DE PARADA :</small>
            <v-col cols="12">
                <v-card color="#D50000" height="45" class="d-flex justify-center align-center">
                    <small style="font-weight: bold; font-size: 15px;">PARADA POR FALTA DE SERVICO</small>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <small style="font-weight: bold;font-size: 18px;color:#0D47A1">ALTERAR STATUS DA PRODUÇÃO PARA :</small>
        </v-row>
        <v-row class="pr-0 pl-1" v-if="appStore.apontamento_atual.tipo == 'Parada'">
            <v-col cols="12">
                <v-btn id="btn-style" color="#43A047" height="45" stacked size="small" @click="trocarStatus">
                    PRODUZINDO
                </v-btn>
            </v-col>
        </v-row>

        <v-row v-else class="pr-0 pl-1">
            <v-col cols="12">
                <v-btn id="btn-style" color="#D50000" height="45" stacked size="small" @click="motivo_de_parada">
                    PARADO
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>
<script>

import { useAppStore } from '../../stores/app';

import { useAppApontamento } from '../../stores/appApontamento';

import { useAppMotivoDeParada } from '../../stores/appMotivosdeParada'

export default {
    name: "eventosApontamento",
    computed: {
        appStore() {
            return useAppStore();
        }
    },
    methods: {
        async motivo_de_parada() {
            useAppMotivoDeParada().apontamento_name = this.appStore.apontamento_atual.name
            await useAppMotivoDeParada().listar_motivos_de_parada();
            useAppStore().showModalMotivoDeParada = true;
        },
        trocarStatus() {

            this.appStore.apontamento_atual.tipo = this.appStore.apontamento_atual.tipo === 'Produção' ? 'Parada' : 'Produção';
            const json_update = {
                tipo: this.appStore.apontamento_atual.tipo
            }
            useAppApontamento().update_apontamento(this.appStore.apontamento_atual.name, json_update)
        }
    },
}

</script>

<style scoped>
#btn-style {
    width: 98%;
    min-width: 100px;
    color: white;
    font-weight: bold;
}
</style>