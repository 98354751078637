<template>
    <v-dialog v-model="appStore.showModalHistorico" transition="dialog-bottom-transition" fullscreen>
        <v-toolbar color="primary">
        </v-toolbar>
        <v-card>
            <v-container fluid class="mt-0">
                <v-row>
                    <v-col cols="12">
                        <v-toolbar height="100" dark color="#37474F">
                            <nuxt-link style="margin-left: 5px;" to="/production">
                                <v-btn @click="onShowHistorico()" id="btn-voltar-historicos-passados" color="#EEEEEE"
                                    variant="flat" size="x-large" text>
                                    VOLTAR
                                </v-btn>
                            </nuxt-link>
                            <v-toolbar-title id="title-historicos-passados">HISTÓRICOS <br>ANTERIORES</v-toolbar-title>
                            <div style="width: 15%;">
                                <ejs-daterangepicker style="height: 40px; font-size: 16px; font-weight: 600;"
                                    :format="'dd/MM/yyyy'" @change="onDateChange" ></ejs-daterangepicker>
                            </div>
                        <template v-slot:append>
                            <v-btn text="PESQUISAR" size="x-large" id="btn-pesquisar-historico-passados"
                                variant="flat" class="ml-3 mr-2" width="200" 
                                color="secondary" @click="OnSearchHistoricos"></v-btn>
                        </template>

                        </v-toolbar>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-card>
                            <ejs-grid :dataSource="appStore.apontamentos_historico" :allowPaging="true"
                                :allowSorting="false" :allowFiltering="true" :enableHover='false'
                                :enableVirtualization='false' :enableHeaderFocus='false' :rowDataBound="onRowDataBound">
                                <e-columns>
                                    <e-column headerText="" field="status" :template="'columnTemplate'" width="80"
                                        textAlign="Center"></e-column>
                                    <e-column headerText="STATUS" field="status" width="80"
                                        textAlign="Center"></e-column>
                                    <e-column headerText="STATUS DA PRODUÇÃO" field="tipo" width="80"
                                        textAlign="Center"></e-column>
                                    <e-column headerText="INICIO" width="100" field="dt_inicio"
                                        textAlign="Center"></e-column>
                                    <e-column headerText="FIM" width="100" field="dt_fim" textAlign="Center"></e-column>
                                    <e-column headerText="OS" width="100" :template="'ordem_producao'"
                                        field="ordem_de_producao" textAlign="Center"></e-column>
                                    <e-column headerText="TURNO" width="100" field="turno"
                                        textAlign="Center"></e-column>
                                    <e-column headerText="MOTIVO DE PARADA" width="100" field="motivo_parada"
                                        textAlign="Left"></e-column>
                                    <e-column headerText="FUNCIONARIO" width="100" field="nome_operador"
                                        textAlign="Left"></e-column>
                                    <e-column headerText="OBSERVAÇÃO" width="100" field="observacao"
                                        textAlign="Left"></e-column>
                                </e-columns>
                                <template v-slot:ordem_producao="{ data }">
                                    <div class="d-flex align-center">
                                        <v-btn variant="text" size="35" @click="ShowDetalheOS(data)" color="secondary"
                                            class="mr-2">
                                            <v-icon color="white" size="35">mdi-folder-open</v-icon>
                                        </v-btn>

                                        <small style="font-weight: bold;font-size: 15px;">{{ data.ordem_de_producao
                                            }}</small>
                                    </div>
                                </template>
                                <template v-slot:columnTemplate="{ data }">
                                    <div class="actions">
                                        <v-btn block size="60" @click="ShowApontamento(data)" color="secondary">
                                            <v-icon size="35">mdi mdi-pencil</v-icon>
                                        </v-btn>
                                    </div>
                                </template>
                            </ejs-grid>

                        </v-card>

                    </v-col>
                </v-row>
            </v-container>
        </v-card>

    </v-dialog>
</template>


<script>
import {
    GridComponent,
    ColumnsDirective,
    ColumnDirective,
    Page,
    Toolbar,
    Edit,
    Sort,
} from "@syncfusion/ej2-vue-grids";

import {
    ColorMaquinaOff,
    ColorMaquinaOn,
    ColorMaquinaSetup,
    ColorManuntencao,
} from "../../common/appColors";

import { useAppStore } from '../../../stores/app';
import { DateRangePickerComponent } from '@syncfusion/ej2-vue-calendars';
import { formatDateSearch } from "../../utils/funcoes"  





export default {
    name: "historico",
    data() {
        return {
            showModalHistorico: false
        }
    },
    methods: {
        async ShowDetalhesAP(data) {
            // useAppApontamento().resourceApontamento()

        },
        onShowHistorico() {
            useAppStore().showModalHistorico = false
        },
        onRowDataBound(args) {
            switch (args.data.tipo) {
                case "Produção":
                    args.row.style.backgroundColor = '#43A047';
                    args.row.style.color = "white";
                    break;
                case "Parada":
                    args.row.style.backgroundColor = "#C62828";
                    args.row.style.color = "white";
                    break;
                default:
                    args.row.style.backgroundColor = "#f8d7da";
                    args.row.style.color = "white";
                    break;
            }
        },
        OnSearchHistoricos() {
            if (this.selectedDates) {
                const dt_inicio = formatDateSearch(this.selectedDates[0]).split(' ')[0];
                const dt_fim = formatDateSearch(this.selectedDates[1]).split(' ')[0];
                useAppStore().get_all_filter_by_date_apontamento(dt_inicio, dt_fim )
                

                console.log("dt iniocio teste",dt_inicio)
                console.log("dt fim teste",dt_fim)
            } else {
                console.log('no else');
            }
        },
        onDateChange(args) {
            this.selectedDates = args.value;
            console.log('Date range selected:', this.selectedDates);
        }
    },
    computed: {
        appStore() {
            return useAppStore()
        }
    },
    components: {
    'ejs-daterangepicker': DateRangePickerComponent,
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    'e-column': ColumnDirective
    },
    provide: {
        grid: [Page, Edit, Toolbar, Sort],
    },
}
</script>

<style></style>