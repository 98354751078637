import { defineStore } from 'pinia'

interface Apontamento {
    name: Number | null,
    nome_operador: String | null,
    recurso: String | null,
    desc_recurso: String | null,
    dt_inicio: String | null,
    dt_fim: String | null,
    ordem_de_producao: String | null,
    status: String | null,
    observacao: String | null
  }


import { useAppStore } from './app'

export const useAppApontamento = defineStore({
    id: 'appApontamento',
    state: (): {
        showModal : boolean,
        titulo_modal : string,
        resourceApontamento : Apontamento,
        showModalObs : boolean,
    } => ({
        showModal : false,
        titulo_modal : '',
        resourceApontamento : {
            name : null,
            nome_operador : null,
            recurso : null,
            desc_recurso : null,
            dt_inicio : null,
            dt_fim : null,
            ordem_de_producao : null,
            status : null,
            observacao : null
        },
        showModalObs : false
    }),
    getters: {
 
    },
    actions: {
        set_apontamento(apontamento: Apontamento) {
            this.resourceApontamento = apontamento
        },
        selecionar_funcionario(funcionario: any) {
            this.resourceApontamento.nome_operador = funcionario.nome
        },
        async update_apontamento(name: any, data : any) {
            let apt = await frappe.call({
                method: 'nxlite.nx_producao.page.apontamento_iot.controller.update_apontamento',
                args: {
                    apt_name: name,
                    changes: data
                },
                callback: function(response) {
                    if (!useAppStore().view_apontamento_historico) {
                        useAppStore().apontamento_atual.name = response.message.name;
                        useAppStore().apontamento_atual.op = response.message.op;
                        useAppStore().apontamento_atual.observacao = response.message.observacao;
                        useAppStore().apontamento_atual.dt_inicio = response.message.dt_inicio;
                        useAppStore().apontamento_atual.status = response.message.status;
                        useAppStore().apontamento_atual.tipo = response.message.tipo;
                        useAppStore().apontamento_atual.serial = response.message.serial;
                        useAppStore().apontamento_atual.turno = response.message.turno; 
                        console.log('apontamento_atualizado tempo real',response.message);
                    }     
                }
            });
            return apt
        }
    }
    })