<template>
    <v-toolbar :color="colorStatusApontamento" class="toolbar-realtime" height="40" id="v-toolbar_production">
        <v-row justify="space-between" class="pl-0 pr-0">
            <v-col cols="1" class="pl-0 pr-0 pt-0 pb-0">
                <v-card border :color="appStore.apontamento_atual.tipo == 'Produção' ? '#43A047' : '#C62828'" style="width: 100%; height: 100%;" class="d-flex align-center justify-center">
                    <v-icon size="35">mdi-lan-connect</v-icon>
                </v-card>
            </v-col>
            <v-col cols="11" class="pl-0 pr-0">
                <small class="ml-2" style="font-weight: bold;font-size: 16px;">TEMPO REAL DA MÁQUINA : {{ tempExecucao
                    }} | ID : {{
                        appStore.maquina_sel.id }} | </small>
                <v-menu v-model="isMenuOpen" :location="'center'" class="mt-5" persistent scrim="true" height="70vh"
                    width="50%">
                    <template v-slot:activator="{ props }">
                        <v-btn id="btn-maquina" variant="tonal"
                            :color="appStore.apontamento_atual.tipo == 'Produção' ? '#1B5E20' : '#E53935'"
                            style="font-weight: bold;font-size: 16px;color:white" v-bind="props" height="30">
                            {{ appStore.maquina_sel.nome }}
                        </v-btn>
                    </template>

                    <v-list persistent class="pt-0 pb-0 mt-0">
                        <v-toolbar color="primary" class="pt-0 pb-0 mt-0">
                            <v-btn color="secondary" variant="elevated" height="60"
                                prepend-icon="mdi-arrow-left-circle">VOLTAR</v-btn>
                            <small class="ml-4" style="font-size: 16px;">LISTA DE MAQUINAS</small>
                        </v-toolbar>
                        <v-list-item class="pt-0 pb-0 mt-0" v-for="(maquina, index) in resourceMaquinas" :key="index">
                            <v-row class="pt-0 pb-0 mt-0" justify="space-between">
                                <v-col cols="12" class="pt-0 pb-0 mt-0">
                                    <v-btn class="mt-1" color="primary" height="60" @click="SelecionarMaquina(maquina)"
                                        style="width: 100%;">{{ maquina.nome
                                        }}</v-btn>
                                </v-col>
                            </v-row>
                            <v-divider :thickness="7" style="font-weight: bold;" inset color="primary"></v-divider>
                        </v-list-item>
                    </v-list>
                </v-menu>

                <small class="ml-2" style="font-weight: bold;font-size: 16px;"> | {{ appStore.apontamento_atual.serial
                    }}</small>
            </v-col>
        </v-row>
    </v-toolbar>
</template>

<script>

import { useAppMaquinas } from '../../stores/appMaquinas';

import { useAppStore } from '../../stores/app';

import { CalculaTempoMaquina } from '../utils/funcoes';

export default {
    name: "headerApontamento",
    components: {
    },
    data() {
        return {
            tempExecucao: '00:00:00',
            isMenuOpen: false,
            time: 0
        };
    },
    computed: {
        resourceMaquinas() {
            return useAppMaquinas().resourceMaquinas;
        },
        colorStatusApontamento() {
            console.log('colorStatusApontamento', useAppStore().apontamento_atual);
            switch (useAppStore().apontamento_atual.tipo) {
                case 'Parada':
                    return '#C62828';
                case 'Produção':
                    return '#43A047';
                default:
                    return 'primary';
            }
        },
        appStore() {
            return useAppStore();
        },
        isMachineSelected() {
            return (useAppStore().maquina_sel.id != null);
        }
    },
    watch: {
        isMachineSelected(newVal) {
            if (!newVal) {
                this.isMenuOpen = true;

            }
        }
    },
    mounted() {
        if (!this.isMachineSelected) {
            this.isMenuOpen = true;
        }
        else {
            this.inicia_count_machine();
        }
    },
    methods: {
        inicia_count_machine() {
            clearInterval(this.time);

            setInterval(() => {
                this.tempoApontamento()
                this.time++;
            }, 1000);
        },
        SelecionarMaquina(maquina) {
            useAppStore().selecionar_maquina(maquina);
            this.inicia_count_machine();
            this.isMenuOpen = false;
        },
        tempoApontamento() {
            this.tempExecucao = CalculaTempoMaquina(new Date(useAppStore().apontamento_atual.dt_inicio)).tempo
        }, 
    },
};
</script>

<style></style>