import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "ml-2",
  style: {"font-size":"18px","color":"#00acc1","font-weight":"bold"}
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_spacer = _resolveComponent("v-spacer")
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_app_bar = _resolveComponent("v-app-bar")

  return (_openBlock(), _createBlock(_component_v_app_bar, {
    color: "primary",
    class: "pt-0 pb-0",
    height: "50"
  }, {
    default: _withCtx(() => [
      _createElementVNode("small", _hoisted_1, _toDisplayString($options.appStore.company), 1 /* TEXT */),
      _createVNode(_component_v_spacer),
      _createVNode(_component_v_btn, {
        color: "#D50000",
        variant: "elevated",
        "prepend-icon": "mdi-exit-to-app"
      }, {
        default: _withCtx(() => [
          _createTextVNode("logout")
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}